import { auth, refreshToken, session, setupAuthFromExternal, shouldRefreshToken } from '$stores/auth';
import { get } from 'svelte/store';
import type { PageLoad } from './$types';

export const load = (async ({ parent }) => {
    await parent();

    await setupAuthFromExternal();

    if (get(session)) {
        // 토큰 리프레시 필요 여부 확인 및 수행
        if (shouldRefreshToken()) {
            await refreshToken();
        }
    }

    return {
        showGNB: false,
        showPageHeader: false,
        auth: get(auth),
        session: get(session),
    };
}) satisfies PageLoad;
