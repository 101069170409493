<script lang="ts">
    import { browser } from '$app/environment';
    import { goto, afterNavigate } from '$app/navigation';
    import { getLauncher } from '$stores/auth';

    import type { PageData } from './$types';

    export let data: PageData;
    const { transformUrl } = data.master;

    // 첫페이지 SSG 생성중에 /index.html 을 생성해야하는데 이걸 layout.ts 에서 팅겨버리면 index.html 이 생성되지 않아 문제가 생길 수 있으니 유의
    if (!data.session) {
        if (browser) {
            goto(transformUrl('/sign-in'), {
                replaceState: true,
                state: {
                    needAlertLogin: true,
                },
            });
        }
    }

    afterNavigate(async () => {
        // 팩토리는 워크스페이스가 1개라서 바로 이동 + GNB가 없어서 바로 이동
        if (data.session) {
            const launcherData = await getLauncher();
            const { workspaceId, tenantId } = launcherData?.[0] || {};
            const workspaceName = `${tenantId}-${workspaceId}`;

            if (data.isCjCj) {
                goto(transformUrl(`/${workspaceName}/chat-without-gnb/`), {
                    replaceState: true,
                });
            } else {
                goto(transformUrl(`/${workspaceName}/main/`), {
                    replaceState: true,
                });
            }
        }
    });
</script>

<svelte:head>
    <title>BHSN.AI Factory</title>
</svelte:head>

<div class="grid h-auto min-h-full"></div>
